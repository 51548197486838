import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import SectionTitle from "../components/section-title"
import ThreeSteps from "../components/three-arrowed-steps"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Icons
import ArrowRightIcon from "../assets/icons/arrow-right.svg"
import PuzzleIcon from "../assets/icons/puzzle.svg"
import AccountTagIcon from "../assets/icons/account-tag.svg"
import DoubleCheckIcon from "../assets/icons/double-check.svg"
import BubblesIcon from "../assets/icons/bubbles.svg"
import PaintIcon from "../assets/icons/paint.svg"
import ClockIcon from "../assets/icons/clock.svg"
import LockIcon from "../assets/icons/lock.svg"

// Logos
import VisaLogo from "../assets/logos/visa.svg"
import MastercardLogo from "../assets/logos/mastercard.svg"
import WooLogo from "../assets/logos/woo.svg"
import ShopifyLogo from "../assets/logos/shopify.svg"
import PrestashopLogo from "../assets/logos/prestashop.svg"
import OpencartLogo from "../assets/logos/opencart.svg"
import OscommerceLogo from "../assets/logos/oscommerce.png"
import WhmcsLogo from "../assets/logos/whmcs.svg"

// Styles
import "../styles/pages/payment-modules.scss"

const PaymentModulesSection = () => {
  const { t } = useTranslation()
  const modules = [
    {
      icon: WooLogo,
      alt: "Woo Commerce",
      title: t("WooCommerce Payment Module"),
      link: "//github.com/Twispay/WooCommerce",
      subtitle: t(
        "Twispay provides the ability to instantly sell your products online using the most popular eCommerce platform for WordPress, which now integrates closely with our payment gateway."
      ),
    },
    {
      icon: ShopifyLogo,
      alt: "Shopify",
      title: t("Shopify Payment Integration"),
      link: "https://twispay.com/shopify-payment-service-provider/ ",
      subtitle: t(
        "Twispay offers a seamless checkout integration to enable Shopify store owners process payments securely and conveniently at very competitive rates."
      ),
    },
    // {
    //   icon: MagentoLogo,
    //   alt: "Magento",
    //   title: t("Magento Payment Module"),
    //   link: "//github.com/Twispay/magento2",
    //   subtitle:
    //     t("Twispay integrates seamlessly with Magento, one of the most popular e-commerce platforms, featuring a quick integration with support for both Magento 1 and 2."),
    // },
    {
      icon: PrestashopLogo,
      alt: "PrestaShop",
      title: t("PrestaShop Payment Module"),
      link: "//github.com/Twispay/PrestaShop",
      subtitle: t(
        "Start accepting online credit card payments in your PrestaShop online store. Twispay is quick and easy to integrate, and seamless for the user to complete purchases."
      ),
    },
    {
      icon: OpencartLogo,
      alt: "OpenCart",
      title: t("OpenCart Payment Module"),
      link: "//github.com/Twispay/OpenCart3.0",
      subtitle: t(
        "Getting your OpenCart customers purchase through your open source platform could not be easier than integrating Twispay's sleek payment plugin."
      ),
    },
    {
      icon: OscommerceLogo,
      alt: "OsCommerce",
      title: t("Payment Module for OsCommerce"),
      link: "//github.com/Twispay/osCommerce",
      subtitle: t(
        "Reliable online payments for OsCommerce; a robust, easy to install and configure e-commerce payment module to get you set and processing cards in minutes."
      ),
    },
    {
      icon: WhmcsLogo,
      alt: "WHMCS",
      title: t("WHMCS Payment Module"),
      link: "//github.com/Twispay/WHMCS",
      subtitle: t(
        "As an internet service provider, you can now quickly accept payments over your WHMCS platform for new and recurring orders, making transaction management a pure breeze."
      ),
    },
  ]
  return (
    <section className="section payment-modules-section is-hero">
      <div className="container">
        <div className="section-header has-text-centered has-margin-bottom-6">
          <h1 className="title has-text-white">
            {t("eCommerce Payment Modules")}
          </h1>
          <p className="subtitle has-text-white">
            {t(
              "Add credit card payments to any website using our customizable modules integrated with the most popular shop providers"
            )}
          </p>
        </div>
        <div className="columns is-centered">
          <div className="column is-three-fifths">
            <div className="modules-wrapper has-background-white is-card">
              {modules.map(module => (
                <div key={module.title} className="is-flex has-margin-bottom-6">
                  <div className="logo-wrapper has-margin-right-4">
                    <img src={module.icon} alt={module.alt} />
                  </div>
                  <div>
                    <h3 className="title has-text-weight-meduim is-spaced is-4">
                      <a
                        href={module.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="title-link"
                      >
                        {module.title}
                        <span className="icon has-margin-left-4">
                          <ArrowRightIcon />
                        </span>
                      </a>
                    </h3>
                    <p className=" has-text-secondary has-margin-bottom-5 is-6">
                      {module.subtitle}
                    </p>
                    <a
                      href={module.link}
                      className="button is-rounded is-soft-white is-small"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Get it")}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="column">
            <div className="form-wrapper is-card has-margin-bottom-5">
              <h3 className="title has-text-centered has-text-weight-medium">
                Let's Get You Started
              </h3>
              <form action="">
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <label className="label " htmlFor="firstname">
                        First name
                      </label>
                      <div className="control">
                        <input className="input" type="text" id="firstname" />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <label className="label " htmlFor="Lastname">
                        Last name
                      </label>
                      <div className="control">
                        <input className="input" type="text" id="Lastname" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label " htmlFor="email">
                    Email address
                  </label>
                  <div className="control">
                    <input className="input" type="text" id="email" />
                  </div>
                </div>
                <div className="field">
                  <label className="label " htmlFor="Plugin">
                    Plugin
                  </label>
                  <div className="control">
                    <div className="select">
                      <select defaultValue="">
                        <option value="" disabled>
                          - Please Select -
                        </option>
                        {modules.map(module => (
                          <option key={module.alt}>{module.alt}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button className="button is-success is-fullwidth has-text-weight-bold">
                      Get Started
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="form-footer">
              <p className="has-text-secondary has-text-centered has-margin-bottom-4">
                We hate spam! <br /> So don't worry, we'll keep emails at a
                minimum
              </p>
              <div
                style={{ justifyContent: "center" }}
                className="columns is-vcentered is-mobile"
              >
                <div className="column is-narrow">
                  <img src={VisaLogo} alt="VISA" />
                </div>
                <div className="column is-narrow">
                  <img src={MastercardLogo} alt="Mastercard" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

const SmoothSection = () => {
  const { t } = useTranslation()
  const steps = [
    {
      icon: <PuzzleIcon />,
      title: t("Install the module"),
    },
    {
      icon: <AccountTagIcon />,
      title: t("Set up your account"),
    },
    {
      icon: <DoubleCheckIcon />,
      title: t("Start processing"),
    },
  ]
  return (
    <section className="section smooth-section has-margin-bottom-5">
      <div className="container">
        <SectionTitle
          title={t("Smooth Integrations")}
          subtitle={t("Easy credit card processing in just a few steps")}
        />
        <ThreeSteps steps={steps} />
        <p className="has-text-secondary has-text-centered">
          {t("7 minutes from here to your complete integration")}
        </p>
      </div>
    </section>
  )
}

const ExperienceSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <BubblesIcon />,
      title: t("Seamless User Experience"),
      description: t(
        "All our payment modules are developed with a keen focus on user interactions and CRO"
      ),
    },
    {
      icon: <PaintIcon />,
      title: t("Complete Personalization"),
      description: t(
        "Customize all the steps in the checkout process according to your standards and brand guidelines"
      ),
    },
    {
      icon: <ClockIcon />,
      title: t("Real-Time Billing"),
      description: t(
        "Process orders faster via automated jobs, and increase your overall sales performance"
      ),
    },
  ]
  return (
    <section className="section smooth-section has-margin-bottom-5">
      <div className="container">
        <SectionTitle
          title={t("Tailor-Made Experience")}
          subtitle={t("Personalise everything from checkout to payment")}
        />
        <div className="columns">
          <div className="column is-two-fifths">
            {items.map(item => (
              <div
                key={item.title}
                className="item-wapper is-flex has-margin-bottom-6"
              >
                <span className="icon has-margin-right-4 is-medium has-text-primary">
                  {item.icon}
                </span>
                <div>
                  <h4 className="title is-5 has-text-weight-medium">
                    {item.title}
                  </h4>
                  <p className="subtitle is-6 has-text-secondary">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="column">
            <Img fluid={imageFluid} className="has-margin-bottom-4" />
            <div className="is-flex is-centered-centered">
              <span className="icon is-small has-margin-right-3 has-text-success">
                <LockIcon />
              </span>
              <p className=" has-text-secondary">
                {t("All card details are encrypted")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const SecureSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section smooth-section has-margin-bottom-5">
      <div className="container">
        <SectionTitle
          title={t("Secure above All")}
          subtitle={t(
            "All transactions are processed in a PCI-DSS certified environment with all data being encrypted from end to end"
          )}
        />
        <div className="columns is-flex is-centered-centered is-mobile has-margin-bottom-5">
          <div className="column is-narrow">
            <img width="100" src={VisaLogo} alt="VISA" />
          </div>
          <div className="column is-narrow">
            <img width="100" src={MastercardLogo} alt="Mastercard" />
          </div>
        </div>
        <div className="columns  is-centered">
          <div className="column is-three-fifths">
            <Img fluid={imageFluid} className="has-margin-bottom-4" />
          </div>
        </div>
      </div>
    </section>
  )
}
const MobileSection = ({ imageFluid }) => {
  const { t } = useTranslation()
  return (
    <section className="section smooth-section has-margin-bottom-5">
      <div className="container">
        <SectionTitle
          title={t("Superbly Mobile")}
          subtitle={t(
            "All our products render beautifully on a complete range of mobile devices, including tablets and smartphones"
          )}
        />
        <div className="columns  is-centered">
          <div className="column is-half">
            <Img fluid={imageFluid} className="has-margin-bottom-4" />
          </div>
        </div>
      </div>
    </section>
  )
}

const PaymentModulesPage = ({ data: images }) => {
  const { t } = useTranslation()
  return (
    <Layout stripeClass="is-purple-violet" pageName="payment-modules">
      <SEO title={t("eCommerce Payment Modules")} />
      <PaymentModulesSection />
      <SmoothSection />
      <ExperienceSection
        imageFluid={images.experienceImage.childImageSharp.fluid}
      />
      <SecureSection imageFluid={images.secureImage.childImageSharp.fluid} />
      <MobileSection imageFluid={images.mobileImage.childImageSharp.fluid} />
    </Layout>
  )
}

export const images = graphql`
  query {
    experienceImage: file(
      relativePath: { eq: "payment-modules-experience.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    secureImage: file(relativePath: { eq: "payment-modules-secure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mobileImage: file(relativePath: { eq: "payment-modules-mobile.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default PaymentModulesPage
